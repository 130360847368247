import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import Layout from '../../layout/Layout';
import Title from '../../components/Title/Title';
import { NOT_FOUND } from '../../constants/httpStatusCodes';
import IErrorView from './interfaces/IErrorView';
import styles from './sass/Error.module.scss';
import IStore from '../../store/interfaces/IStore';

const ErrorView = ({ statusCode }: IErrorView) => {
  const { t } = useTranslation('error');
  const userInfo = useSelector((state:IStore) => state.user);
  // eslint-disable-next-line no-console
  console.log(statusCode);
  return (
    <Layout showAuthHandling={!!userInfo.id}>
      <div className={styles.errorBody}>
        <Title>
          {t(statusCode === NOT_FOUND ? 'pageNotFound' : 'internalServerError')}
        </Title>
      </div>
    </Layout>
  );
};

export default ErrorView;
