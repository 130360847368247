import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import IError from '../src/pages/Error/interfaces/IError';
import IServerSideProps from '../src/interfaces/IServerSideProps';
import ErrorView from '../src/pages/Error/ErrorView';
import checkAccessToken from '../src/utils/checkAccessToken';

const Error = ({ statusCode }: IError) => (
  <ErrorView statusCode={statusCode} />
);

export const getServerSideProps = async ({ locale, req, res }: IServerSideProps) => {
  checkAccessToken(req.headers.cookie);
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'error'])),
      statusCode: res?.statusCode || 404,
    },
  };
};

export default Error;
